<script>
  import stars from "/src/images/stars.png";
  import frozenStar from "/src/images/frozen-star.webp";
  import frozenStarFallback from "/src/images/frozen-star.png";
  import next from "/src/images/next.png";
  import coolerVideo from "/src/images/cooler.mp4";
  import feature1 from "/src/images/feature-1.webp";
  import feature1Fallback from "/src/images/feature-1.png";
  import feature2 from "/src/images/feature-2.webp";
  import feature2Fallback from "/src/images/feature-2.png";
  import feature3 from "/src/images/feature-3.webp";
  import feature3Fallback from "/src/images/feature-3.png";
</script>


<style>
  .section {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: #e6f0ff;
  }

  @media (min-width: 768px) {
    .hero-background {
      background-image: url("/src/images/top-dk.gif");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .container {
    display: inline-flex;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 970px;
    align-items: stretch;
    height: auto;
  }

  @media (min-width: 768px) {
    .container {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .content {
    display: inline-block;
    min-height: 200px;
    padding-top: 0;
    z-index: 50;
    width: 100%;
    position: relative;
  }

  @media (min-width: 768px) {
    .content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .header {
    margin-bottom: 40px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
  }

  .header-content {
    min-height: 50px;
    padding-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 768px) {
    .header-content {
      padding: 0;
    }
  }

  .review {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .stars-image {
    width: 100%;
    max-width: 76px;
  }

  .review-text {
    margin-left: 14px;
    color: #313131;
    font-size: 16px;
  }

  .title {
    margin-bottom: 17px;
    font-size: 34px;
    line-height: 41px;
    font-weight: bold;
    width: 100%;
  }

  @media (min-width: 768px) {
    .title {
      margin-bottom: 15px;
      width: 62%;
    }
  }

  @media (min-width: 1024px) {
    .title {
      font-size: 52px;
      line-height: 60px;
      width: 55%;
    }
  }

  .video-container {
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    .video-container {
      display: none;
    }
  }

  .video {
    width: 100%;
    object-fit: fill;
    outline: 0;
  }

  .features {
    min-height: 50px;
    max-width: 100%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media (min-width: 768px) {
    .features {
      max-width: 520px;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .feature {
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
  }

  .feature-icon {
    width: 100%;
    max-width: 25px;
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    .feature-icon {
      max-width: 29px;
    }
  }

  .feature-text {
    margin: 0;
    font-size: 16px;
    line-height: 27px;
    max-width: 390px;
  }

  @media (min-width: 768px) {
    .feature-text {
      font-size: 18px;
    }
  }

  @media (min-width: 1024px) {
    .feature-text {
      font-size: 20px;
    }
  }

  .cta-button {
    display: flex;
    padding: 20px;
    border-radius: 3px;
    justify-content: center;
    background-repeat: repeat;
    width: 100%;
    align-items: stretch;
    max-width: 410px;
    text-decoration: none;
    background-image: -webkit-linear-gradient(0deg, #056cb8 0%, #35ccf5 100%);
  }

  @media (min-width: 768px) {
    .cta-button {
      padding: 20px 30px;
      justify-content: flex-start;
    }
  }

  .cta-icon {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    .cta-icon {
      margin-right: 21px;
    }
  }

  .cta-text {
    margin-left: 5px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
  }

  @media (min-width: 768px) {
    .cta-text {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .additional-features {
    min-height: 50px;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    margin-bottom: 35px;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 768px) {
    .additional-features {
      flex-direction: row;
      padding: 0;
      margin-bottom: 0;
    }
  }

  .additional-feature {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    .additional-feature {
      width: 30%;
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  .additional-feature-icon {
    width: 100%;
    max-width: 73px;
    margin-right: 23px;
  }

  @media (min-width: 768px) {
    .additional-feature-icon {
      max-width: 96px;
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  .additional-feature-text {
    line-height: 20px;
  }

  .feature-divider {
    display: none;
  }

  @media (min-width: 768px) {
    .feature-divider {
      display: block;
      min-height: 114px;
      width: 1px;
      background-color: #00aeef;
    }
  }
</style>


<section class="section hero-background">
  <div class="container" style="position: relative;">
    <div class="content">
      <div class="header">
        <div class="header-content">
          <div class="review">
            <img src={stars} alt="review stars" class="stars-image" />
            <div class="review-text">4.9 based on 2000+ Verified Reviews</div>
          </div>
          <div class="title">
            Keep Yourself Cool Without Spending a Fortune!
          </div>
        </div>
        <div class="video-container">
          <video class="video" loop autoplay muted playsinline>
            <track kind="captions" srclang="en" label="English" />
            <source src={coolerVideo} type="video/mp4" />
          </video>
        </div>
        <div class="features">
          <div class="feature">
            <picture>
              <source srcset={frozenStar} type="image/webp" />
              <img
                src={frozenStarFallback}
                alt="frozen icon"
                class="feature-icon"
              />
            </picture>
            <div class="feature-text">Rapidly cool your space instantly!</div>
          </div>
          <div class="feature">
            <picture>
              <source srcset={frozenStar} type="image/webp" />
              <img
                src={frozenStarFallback}
                alt="frozen icon"
                class="feature-icon"
              />
            </picture>
            <div class="feature-text">
              Completely portable and rechargeable to take anywhere with you!
            </div>
          </div>
          <div class="feature">
            <picture>
              <source srcset={frozenStar} type="image/webp" />
              <img
                src={frozenStarFallback}
                alt="frozen icon"
                class="feature-icon"
              />
            </picture>
            <div class="feature-text">
              Slash electric bills & keep that extra cash for yourself!
            </div>
          </div>
          <div class="feature">
            <picture>
              <source srcset={frozenStar} type="image/webp" />
              <img
                src={frozenStarFallback}
                alt="frozen icon"
                class="feature-icon"
              />
            </picture>
            <div class="feature-text">Now with Insta-Frost technology!</div>
          </div>
          <a
            href="https://rt.chillwellshopp.com/click"
            class="cta-button"
          >
            <img src={next} alt="next" class="cta-icon" />
            <div class="cta-text">GET 50% OFF CHILLWELL NOW</div>
          </a>
        </div>
      </div>
      <div class="additional-features">
        <div class="additional-feature">
          <picture>
            <source srcset={feature1} type="image/webp" />
            <img
              src={feature1Fallback}
              alt="feature 1"
              class="additional-feature-icon"
            />
          </picture>
          <div class="additional-feature-text">Cordless & portable</div>
        </div>
        <div class="feature-divider"></div>
        <div class="additional-feature">
          <picture>
            <source srcset={feature2} type="image/webp" />
            <img
              src={feature2Fallback}
              alt="feature 2"
              class="additional-feature-icon"
            />
          </picture>
          <div class="additional-feature-text">Rapid cooling</div>
        </div>
        <div class="feature-divider"></div>
        <div class="additional-feature">
          <picture>
            <source srcset={feature3} type="image/webp" />
            <img
              src={feature3Fallback}
              alt="feature 3"
              class="additional-feature-icon"
            />
          </picture>
          <div class="additional-feature-text">Extremely energy efficient</div>
        </div>
      </div>
    </div>
  </div>
</section>
