<script>
  export let question;
  export let ans;
  export let isOpen;
  export let toggle;
  import icon from "/src/images/signs.png";
</script>

<style>
  .faq-question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 32px;
    font-weight: normal;
    font-size: 18px;
    line-height: 16px;
    color: #4b5563;
    position: relative;
    z-index: 10;
    margin: 0;
    cursor: pointer;
    border-radius: 6px;
  }


  .question-text {
    font-weight: bold;
    line-height: 1.2;
  }

  .icon-container {
    position: absolute;
    right: 20px;
    top: 7px;
  }

  .icon {
    width: 14px;
    height: 14px;
  }

  .icon-open {
    transform: rotate(-45deg);
  }

  .answer-text {
    padding-left: 30px;
    font-size: 14px;
    padding-top: 8px;
  }
</style>

<div class="faq-item">
  <div class="faq-question" on:click={toggle}>
    <span class="question-text">{question}</span>
    <div class="icon-container">
      {#if isOpen}
        <img src={icon} alt="Icon" class="icon icon-open" />
      {:else}
        <img src={icon} alt="Minus Icon" class="icon" />
      {/if}
    </div>
  </div>
  {#if isOpen}
    <p class="answer-text">
      {ans}
    </p>
  {/if}
</div>
