<script>
  import review1 from "/src/images/review-1.jpg";
  import review2 from "/src/images/review-2.jpg";
  import review3 from "/src/images/review-3.jpg";
  import ReviewCard from "./Review-Card.svelte";
</script>

<style>
  .reviews-section {
    margin: 0 auto;
    display: inline-flex;
    align-items: stretch;
    width: 100%;
  }

  .reviews-section .container {
    max-width: 970px;
    padding: 0 15px 50px;
    margin: auto;
    height: auto;
    display: inline-flex;
    width: 100%;
    align-items: stretch;
  }

  .reviews-section .content-wrapper {
    width: 100%;
  }

  .reviews-section .section-title {
    margin: 0 auto 30px;
    font-size: 26px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    width: 100%;
  }

  .reviews-section .review-grid {
    display: grid;
    gap: 20px;
  }

  @media (min-width: 768px) {
    .reviews-section .review-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (min-width: 1024px) {
    .reviews-section .section-title {
      font-size: 40px;
      line-height: 48px;
    }
  }
</style>

<section class="reviews-section">
  <div class="container">
    <div class="content-wrapper">
      <h2 class="section-title">
        What Satisfied Customers <br /> Have to Say
      </h2>
      <div class="review-grid">
        <ReviewCard
          review={{
            description: `A summer must-have! I saved so much on utility bills just by switching to ChillWell 2.0 for the master bedroom in my house.`,
            name: "Stephanie R.",
            location: "Sacramento, CA",
            image: review1,
          }}
        />
        <ReviewCard
          review={{
            description: `Best mini cooler I've tried and I've been through quite a few. Lasted me all summer long even with daily use and maximum power on.`,
            name: "Jules G.",
            location: "Scottsdale, AZ",
            image: review2,
          }}
        />
        <ReviewCard
          review={{
            description: `I like that it's portable! I've tried bringing it to work and used it in my shed and it works great. Hoping to get more of these in the future.`,
            name: "Barry R.",
            location: "Phoenix, AZ",
            image: review3,
          }}
        />
      </div>
    </div>
  </div>
</section>
