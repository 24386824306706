<script>
  import ad from "/src/images/ad.webp";
  import dollar from "/src/images/dollar.webp";
  import shipping from "/src/images/shipping.webp";
</script>

<style>
  .offer-container {
    max-width: 1024px; /* 64rem to px */
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
    padding: 56px 0; /* 3.5rem to px */
    box-sizing: border-box;
  }

  .offer-wrapper {
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
  }

  @media (min-width: 768px) {
    .offer-wrapper {
      flex-direction: row;
    }
  }

  .offer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px;
    border: 4px dashed #facc15;
    box-sizing: border-box;
    max-width: 100%;
  }

  .offer-header {
    text-align: center;
  }

  .offer-title {
    letter-spacing: 2px;
    margin: 0;
    line-height: 75px;
    font-size: 50px;
    color: #000;
    font-weight: 850;
  }

  .offer-subtitle {
    margin: 0px 0;
    line-height: 34px;
    font-size: 39px;
    font-weight: 800;
    color: #e90000;
    text-align: center;
  }

  .offer-banner {
    margin: 24px auto;
    max-width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 0.05s ease-in-out;
  }

  .offer-details {
    font-weight: 700;
    font-size: 25px;
    min-height: 50px;
    background-color: #fff8f0;
    padding: 56px;
    width: 100%; /* Ensure it stays within the parent */
    box-sizing: border-box; /* Ensure padding is included in width */
  }

  .offer-description {
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    line-height: 34px;
  }

  .discount-highlight {
    color: #00aeef;
  }

  .offer-features {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;
    width: 100%; /* Ensure it stays within the parent */
  }

  @media (min-width: 768px) {
    .offer-features {
      flex-direction: row;
    }
  }

  .feature {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 32px;
    flex: 1; /* Allow feature items to grow and shrink */
  }

  .feature-icon {
    width: 64px;
    height: 64px;
    margin-right: 10px; /* Space between icon and text */
  }

  .feature-text {
    font-size: 20px;
    line-height: 25px;
    font-weight: lighter;
    letter-spacing: 0.8px;
    white-space: nowrap; /* Prevent wrapping */
  }

  @media (min-width: 768px) {
    .feature-text {
      font-size: 25px;
      line-height: 28px;
      letter-spacing: 1.5px;
    }
  }

  .cta-container {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #16a34a;
    margin-top: 48px;
    line-height: 34px;
    width: 100%;
    box-sizing: border-box;
  }

  .cta-button {
    color: #16a34a;
    text-decoration: none;
  }

  .cta-button:hover {
    color: #15803d;
  }
</style>

<div class="offer-container">
  <div class="offer-wrapper">
    <div class="offer-content">
      <div class="offer-header">
        <h2 class="offer-title">Exclusive Offer Today Only</h2>
        <h3 class="offer-subtitle">Limited Time Offer!</h3>
      </div>
      <picture>
        <img src={ad} class="offer-banner" alt="ad banner" />
      </picture>
      <div class="offer-details">
        <p class="offer-description">
          Special LIMITED TIME Discount: <span class="discount-highlight"
            >Get up to 50% OFF ChillWell NOW</span
          >
        </p>
        <div class="offer-features">
          <div class="feature">
            <img src={shipping} class="feature-icon" alt="shipping" />
            <span class="feature-text">Fast shipping</span>
          </div>
          <div class="feature">
            <img src={dollar} class="feature-icon" alt="money-back guarantee" />
            <span class="feature-text">60-day money-back guarantee</span>
          </div>
        </div>
        <div class="cta-container">
          <a
            href="https://rt.chillwellshopp.com/click"
            class="cta-button" style="TEXT-DECORATION: underline">Click Here to Claim Your 50% OFF Discount</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
