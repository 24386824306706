<script>
  import FeatureCard from "./Feature-Card.svelte";
  import img1 from "/src/images/1.jpg";
  import img3 from "/src/images/3.jpg";
  import img5 from "/src/images/5.jpg";
  import vid1 from "/src/images/vid1.mp4";
  import vid2 from "/src/images/vid2.mp4";
  import vid3 from "/src/images/vid3.mp4";
</script>

<section class="feature-section">
  <div class="feature-container">
    <h2 class="feature-title">Stay Cool and Save BIG This Summer!</h2>
    <div class="feature-row">
      <FeatureCard
        data={{
          title: "Insta-Frost Technology",
          description: `Get hit with an <INSTANT BLAST of Chilling Polar Air the second you turn the device on! No waiting!`,
          image: vid1,
        }}
      />
      <FeatureCard
        data={{
          title: "BIG Savings",
          description: `Replace your costly AC with a shockingly low electricity bill that won't make you sweat!`,
          image: img1,
        }}
      />
      <FeatureCard
        data={{
          title: "Customizable Cooling",
          description: `Choose between 3 speeds, high medium and low, + TURBO mode for the ultimate blast of cooling!`,
          image: img3,
        }}
      />
    </div>
    <div class="feature-row">
      <FeatureCard
        data={{
          title: "Easy to Use",
          description: `No set up required! Ready to use out of the box! It’s that easy!`,
          image: vid2,
        }}
      />
      <FeatureCard
        data={{
          title: "Rapid Room Cooling",
          description: `Cools down your space within 10 minutes. No need to wait for the rest of the house to cool down!`,
          image: img5,
        }}
      />
      <FeatureCard
        data={{
          title: "Portable for Your Pleasure",
          description: `The rechargeable battery makes it easy to stay chill from room to room... or even out on your patio!`,
          image: vid3,
        }}
      />
    </div>
  </div>
</section>

<style>
  .feature-section {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
    display: inline-flex;
    align-items: stretch;
    width: 100%;
  }

  .feature-container {
    max-width: 970px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 118px;
    margin: auto;
    height: auto;
    width: 100%;
  }

  .feature-title {
    margin-top: 60px;
    margin-bottom: 50px;
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .feature-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 200px;
  }

  @media (min-width: 768px) {
    .feature-row {
      flex-direction: row;
      margin-bottom: 40px;
    }
  }
</style>
