<script>
  import dmca from "/src/images/dmca.jpg";
</script>

<style>
  .small-logo img {
    width: 110px; 
    height: auto; 
  }


  .section {
    margin:  auto;
    width: 100%;
    background-color: #222;
    max-width: 100%;
    height: 240px;
    
  }

  .container {
    margin: 0 auto;
    width: 100%;
    padding: 10rem 5rem; 
    
  }

  @media (min-width: 640px) {
    .container {
      padding: 2rem 1rem; 
    }
  }

  .block {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .flex-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 768px) {
    .flex-row {
      flex-direction: row;
    }
  }

  .flex-1 {
    flex: 1;
    box-sizing: border-box;
    margin-right: 50px;
  }

  .text {
    margin: 0;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #9ca3af;
    display: inline-block;
    box-sizing: border-box;
    margin-top: 1rem; 

  }

  .link {
    color: #9ca3af;
    text-decoration: none;
    
  }

  .separator {
    padding: 0 0.375rem;
  }
  .Copyright{
    margin-left: 180px;
    max-width: 800px;
    
  }
  .flex-wrap {
    justify-content: space-between;
    margin-bottom: 0.625rem;
    white-space: nowrap;
    margin-right: 50px;
  }

  .mb-2-5 {
    margin-bottom: 0.625rem;
  }


  /* dmca */
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    box-sizing: border-box;
  }

  .img {
    height: 2rem;
    max-width: 100%;
    width: auto;
  }

  .address {
    font-size: 0.75rem;
    line-height: 1rem;
    display: inline-block;
    border-radius: 4px;
    margin-top: 5px;
  }
</style>

<section class="section">
  <div class="container">
    <div class="block">
      <div class="flex-row">
        <div class="Copyright flex-1">
          <p class="text">Copyright © 2024 ChillWell All Rights Reserved.</p><br>
          <p class="text address">ChillWell, 21 Law Drive Fairfield, NJ 07004</p>
        </div>
        <div class="flex-wrap">
          <div class="flex-1 mb-2-5">
            <p class="text">
              <a href="https://chillwellshopp.com/terms" class="link">Terms and conditions</a>
            </p>
            <p class="separator text">|</p>
            <p class="text">
              <a href="https://chillwellshopp.com/privacy" class="link">Privacy Policy</a>
            </p>
            <p class="separator text">|</p>
            <p class="text">
              <a href="chillwell_manual.pdf" class="link" target="_blank">Manual</a>
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex-1 flex-center">
          <div class="flex-center">
            <div class="contents small-logo">
              <a href="https://www.dmca.com/Protection/Status.aspx?ID=ef1fadd9-e577-4b7f-ac63-515756019c06&amp;refurl=https://www.chillwellshop.com/en/newindex.html&amp;ctr_cssid=77019824fd6c45a1a265dcc487905a55&amp;ctr_io=2&amp;ctr_ppid=64237f80feed801960cfe05e&amp;ctr_psid=62e3a7d0ef95b12a90b9cf1f&amp;ctr_ppu=https%3A%2F%2Fwww.chillwellshop.com%2Fen%2Fnewindex.html" 
              title="DMCA.com Protection Status">
                <img alt="DMCA.com Protection Status" src={dmca}> 
              </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
