<script>
  import banner1Video from "/src/images/banner-1.mp4";
  import banner2Video from "/src/images/banner-2.mp4";
  import banner3 from "/src/images/banner-3.webp";
  import banner3Fallback from "/src/images/banner-3.jpg";
  import frozenStarFallback from "/src/images/frozen-star.png";
</script>

<section class="video-section">
  <div class="video-container">
    <div class="video-content">
      <div class="video-wrapper">
        <video class="video" loop autoplay muted playsinline>
          <track kind="captions" srclang="en" label="English" />
          <source src={banner1Video} type="video/mp4" />
        </video>
      </div>
      <div class="video-text">
        <h2 class="video-title">
          The Smart and Affordable Way to<br> Cool Your Home
        </h2>
        <p class="video-description">
          Did you know that AC is the most expensive part of your utilities bill
          each year?
        </p>
        <p class="video-description bold">
          With ChillWell 2.0, you can ensure you stay cool wherever you go in
          your home - without the huge bill!
        </p>
        <p class="video-description">
          Don't waste thousands of dollars on expensive, slow, and inefficient
          AC units! Get budget-friendly, fast-acting cooling with ChillWell 2.0.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="features-section">
  <div class="features-container">
    <div class="features-content">
      <div class="features-text">
        <h2 class="features-title">Packed With <br /> Summer-Ready Features</h2>
        <p class="features-description">
          Your ChillWell 2.0 is designed to keep you comfortable and chill all
          summer long!
        </p>
        <div class="feature-item">
          <img
            src={frozenStarFallback}
            alt="frozen icon"
            class="feature-icon"
          />
          <p class="feature-text">
            <span class="bold">Extra Cooling Features</span> – Four speeds -Turbo,
            High, Medium, and Low- and a water tank for ice cubes
          </p>
        </div>
        <div class="feature-item">
          <img
            src={frozenStarFallback}
            alt="frozen icon"
            class="feature-icon"
          />
          <p class="feature-text">
            <span class="bold">USB Cable Charging</span> – Take your ChillWell 2.0
            anywhere you go and charge it back up as needed
          </p>
        </div>
        <div class="feature-item">
          <img
            src={frozenStarFallback}
            alt="frozen icon"
            class="feature-icon"
          />
          <p class="feature-text">
            <span class="bold">Night Light</span> – Great addition to your child's
            bedtime routine
          </p>
        </div>
      </div>
      <div class="features-video-wrapper">
        <video class="video" loop autoplay muted playsinline>
          <track kind="captions" srclang="en" label="English" />
          <source src={banner2Video} type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</section>

<section class="product-section">
  <div class="product-container">
    <div class="product-image">
      <picture>
        <source type="image/webp" src={banner3} />
        <img src={banner3Fallback} alt="ChillWell 2.0" class="product-img" />
      </picture>
    </div>
    <div class="product-text">
      <h2 class="product-title">Super Small, Super Cooling Effect!</h2>
      <p class="product-description">
        Don't let its small size fool you ─ <span class="bold"
          >ChillWell 2.0 instantly produces a strong blast of cool air.</span
        >
      </p>
      <p class="product-description">
        This portable air chiller is compact enough to carry from room to room.
        Instantly cool up your bedroom, your home office, and even your kitchen
        while you cook your favorite meals!
      </p>
      <p class="product-description">
        Wherever you are, ChillWell 2.0 will provide instant cooling!
      </p>
    </div>
  </div>
</section>

<style>
  /* Common styles */
  .bold {
    font-weight: bold;
  }

  /* Video Section */
  .video-section {
    margin: 0 auto;
    display: inline-flex;
    width: 100%;
    align-items: stretch;
  }

  .video-container {
    max-width: 1120px;
    margin: auto;
    height: auto;
    display: inline-flex;
    width: 100%;
    align-items: stretch;
  }

  .video-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 200px;
  }

  .video-wrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: block;
    min-height: 50px;
  }

  .video {
    width: 100%;
    object-fit: fill;
    outline: 0;
  }

  .video-text {
    width: 100%;
    padding: 0 15px 30px;
  }

  .video-title {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 30px;
    width: 100%;
    font-weight: 800;
  }

  .video-description {
    margin-bottom: 30px;
    line-height: 26px;
    font-size: 16px;
  }

  /* Features Section */
  .features-section {
    margin: 0 auto;
    display: inline-flex;
    width: 100%;
    align-items: stretch;
  }

  .features-container {
    max-width: 1120px;
    margin: auto;
    height: auto;
    display: inline-flex;
    width: 100%;
    align-items: stretch;
    justify-content: flex-end;
  }

  .features-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 200px;
  }

  .features-text {
    width: 100%;
    min-height: 50px;
    padding: 40px 15px 0;
  }

  .features-title {
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 30px;
    font-weight: 800;
    width: 100%;
  }

  .features-description {
    line-height: 25px;
    font-size: 16px;
    max-width: 400px;
    margin-bottom: 20px;
    width: 100%;
  }

  .feature-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;
  }

  .feature-icon {
    width: 100%;
    max-width: 25px;
    margin-right: 10px;
  }

  .feature-text {
    line-height: 25px;
    font-size: 16px;
  }

  .features-video-wrapper {
    width: 100%;
    min-height: 50px;
  }

  /* Product Section */
  .product-section {
    max-width: 64rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
    padding: 3.5rem 0;
    box-sizing: border-box;
  }

  .product-container {
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
  }

  .product-image {
    width: 100%;
    box-sizing: border-box;
  }

  .product-img {
    width: 100%;
    max-width: 100%;
    height: auto;
    transition: opacity 75ms ease-in-out;
  }

  .product-text {
    width: 100%;
    margin-top: 2rem;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .product-title {
    font-size: 1.6rem;
    font-weight: 800;
    margin-bottom: 1.25rem;
  }

  .product-description {
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 1.75rem;
    line-height: 1.5;
  }

  /* Media Queries */
  @media (min-width: 768px) {
    .video-content {
      flex-direction: row;
    }

    .video-wrapper {
      width: 50%;
    }

    .video-text {
      width: 450px;
      padding: 0 0 0 20px;
    }

    .video-title {
      font-size: 40px;
      line-height: 48px;
    }

    .video-description {
      line-height: 30px;
      font-size: 20px;
    }

    .features-container {
      padding: 70px 15px 0;
    }

    .features-content {
      flex-direction: row;
    }

    .features-text {
      width: 50%;
      padding: 0 30px 0 0;
    }

    .features-title {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 45px;
    }

    .features-description,
    .feature-text {
      font-size: 20px;
      line-height: 30px;
    }

    .feature-icon {
      max-width: 29px;
    }

    .features-video-wrapper {
      width: 50%;
      padding-left: 20px;
    }

    .product-container {
      flex-direction: row;
    }

    .product-image,
    .product-text {
      width: 50%;
      padding: 0 1rem;
    }

    .product-text {
      margin-top: 0;
      padding: 0 1.25rem;
    }

    .product-title {
      font-size: 2.25rem;
      text-align: left;
    }

    .product-description {
      font-size: 1.25rem;
      text-align: left;
      line-height: 2rem;
    }
  }

  @media (min-width: 1024px) {
    .features-text {
      width: 500px;
    }

    .features-title,
    .video-title {
      font-size: 40px;
      line-height: 48px;
    }

    .features-description,
    .feature-text,
    .video-description {
      font-size: 20px;
      line-height: 30px;
    }
  }
</style>
