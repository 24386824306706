<script>
  import Ad from "./lib/Ad.svelte";
  import Banners from "./lib/Banners.svelte";
  import Faqs from "./lib/Faqs.svelte";
  import Features from "./lib/Features.svelte";
  import Footer from "./lib/Footer.svelte";
  import Header from "./lib/Header.svelte";
  import Hero from "./lib/Hero.svelte";
  import Reviews from "./lib/Reviews.svelte";
  import Swiper from "./lib/Swiper.svelte";
</script>

<div class="container">
  <Header />
  <main>
    <Swiper />
    <Hero />
    <Features />
    <Banners />
    <Reviews />
    <Ad />
    <Faqs />
    <Footer />
  </main>
</div>


<style>
  /* Prevent horizontal overflow */

  .container {
    max-width: 1440px;
    margin: 0 auto;
    overflow: hidden;
  }

</style>