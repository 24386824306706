<script>
  export let data;
</script>

<div class="feature-card">
  <div class="feature-media-container">
    {#if data.image.endsWith('.mp4')}
      <video src={data.image} autoplay loop muted class="feature-video"></video>
    {:else}
      <img src={data.image} alt={data.title} class="feature-image" />
    {/if}
  </div>
  <div class="feature-title">{data.title}</div>
  <div class="feature-description">{data.description}</div>
</div>

<style>
  .feature-card {
    min-height: 50px;
    width: 100%;
    margin-bottom: 40px;
  }

  @media (min-width: 768px) {
    .feature-card {
      width: 32%;
      margin-bottom: 0;
    }
  }

  .feature-media-container {
    min-height: 50px;
    position: relative;
    overflow: hidden;
  }

  .feature-image,
  .feature-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .feature-title {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
  }

  .feature-description {
    margin: 0;
    line-height: 25px;
  }
</style>
