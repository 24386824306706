<script>
  import logo from "/src/images/logo.webp";
  import logoFallback from "/src/images/logo.png";
</script>

<header class="header">
  <div class="header-container">
    <div class="header-content">
      <div>
        <picture>
          <source srcset={logo} type="image/webp" />
          <img src={logoFallback} alt="Welcome" class="header-logo" />
        </picture>
      </div>
      <div class="header-text">
        Special 50% Off Discount – Limited Time Only!
      </div>
    </div>
  </div>
</header>

<style>
  .header {
    width: 100%;
    background-repeat: repeat;
    background-size: 100%;
    background-image: -webkit-linear-gradient(45deg, #056cb8 0%, #35ccf5 100%);
  }

  .header-container {
    max-width: 970px;
    padding: 35px 15px;
    margin: 0 auto;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo {
    max-width: 282px;
    height: auto;
  }

  .header-text {
    line-height: 24px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    margin-left: 30px;
  }

  @media (max-width: 1024px) {
    .header-container {
      padding: 15px;
    }

    .header-logo {
      max-width: 188px;
      height: 22px;
    }

    .header-text {
      line-height: 14px;
      font-size: 12px;
      margin-left: 0;
    }
  }
</style>
