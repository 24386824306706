<script>
  import { writable } from 'svelte/store';
  import Faq from "./Faq-Item.svelte";

  const faqs = [
    { question: 'How does ChillWell 2.0 work?', ans: 'ChillWell 2.0 portable air chiller uses Insta-Frost Technology that helps turn hot air into cold, moist air.' },
    { question: 'What does the degree of cooling depend on?', ans: 'The degree of cooling depends on three factors: the outside air temperature, the air humidity, and the device settings you choose. ChillWell 2.0 portable air chiller helps reduce the temperature of the air through evaporative cooling.' },
    { question: 'How is ChillWell 2.0 powered?', ans: 'ChillWell 2.0 portable air chiller is rechargeable and should be charged with the included USB cable.' },
    { question: 'How do I know when ChillWell 2.0 is fully charged?', ans: 'The charging light will begin blinking to indicate the unit is charging. When the light remains solid, ChillWell 2.0 portable air chiller is fully charged.' },
    { question: 'How long will ChillWell 2.0 run?', ans: 'ChillWell 2.0 fully charged will run for 3.5 hours depending on speed setting, room temperature levels, and room humidity levels. When plugged in ChillWell 2.0 portable air chiller can last 8-12 hours on a single fill depending on speed setting, room temperature levels, and room humidity levels.' },
    { question: 'How often should I replace the cooling cartridge?', ans: 'It is recommended to replace the cooling cartridge every 1-3 months, depending on usage. There is no indication system that notifies you when the cooling cartridge should be replaced.' },
    { question: 'What material is the cooling cartridge made of?', ans: 'The cooling cartridge is made of a sponge material.' },
    { question: 'What are the LED night light colors?', ans: 'Blue, Red, White, Teal, Purple, Yellow, Green, and Color Cycle.' },
    { question: 'Can the LED night light be turned off?', ans: 'Yes, simply press through all the color options until the night light turns off.' },
    { question: 'How much water does the tank hold?', ans: 'ChillWell 2.0 has a 550ml water tank' }
  ];

  let openIndex = writable(-1);

  function toggle(index) {
    openIndex.update(n => n === index ? -1 : index);
  }
</script>

<style>
  .faq-div {
    background-color: #fafafa;
    max-width: 1440px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  .faq-container {
    background-color: #fafafa;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: stretch;
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }

  .faq-wrapper {
    background-color: #fafafa;
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-direction: column;
    gap: 4px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .faq-header {
    margin-bottom: 16px;
  }

  .faq-title {
    font-weight: bold;
    font-family: sans-serif;
    font-size: 40px;
    line-height: 28px;
    letter-spacing: normal;
  }
</style>

<div class="faq-div">
  <div class="faq-container">
    <div class="faq-wrapper">
      <div class="faq-header">
        <h3 class="faq-title">FAQ</h3>
      </div>

      {#each faqs as { question, ans }, index}
        <Faq
          {question}
          {ans}
          isOpen={$openIndex === index}
          toggle={() => toggle(index)}
        />
      {/each}
    </div>
  </div>
</div>
