<script>
  import swiper from "/src/images/swiper.gif";
  import { onMount } from "svelte";

  let swiperContainer;
  let startX;

  onMount(() => {
    if (swiperContainer) {
      swiperContainer.addEventListener("touchstart", handleTouchStart, false);
      swiperContainer.addEventListener("touchmove", handleTouchMove, false);
    } else {
      console.log("");
    }
  });

  function handleTouchStart(evt) {
    evt.preventDefault(); // Prevent default scrolling behavior
    const firstTouch = evt.touches[0];
    startX = firstTouch.clientX;
  }

  function handleTouchMove(evt) {
    evt.preventDefault(); // Prevent default scrolling behavior
    if (!startX) {
      return;
    }

    let endX = evt.touches[0].clientX;
    let diffX = startX - endX;

    if (diffX > 50) {
      document.getElementById("swipe-url").click();
      startX = null;
    }
  }

</script>

<a  href="https://rt.chillwellshopp.com/click">
<div bind:this={swiperContainer} class="swiper-container">
  <div class="swiper-image-container">
    <img src={swiper} alt="swiper" class="swiper-image" />
  </div>
  <a
    id="swipe-url"
    href="https://rt.chillwellshopp.com/bo"
    class="swiper-link"
  >
    <div class="swiper-link-text">
      Swipe Now for <span class="swiper-link-highlight">50% OFF</span>
    </div>
  </a>
</div>
</a>

<style>

/* everything else */
@media (min-width: 1024px) {
  .swiper-container {
  position: fixed; /* Use fixed position to float it on top */
  top: 50%; 
  right: 0;
  transform: translateY(-50%); /* Center vertically */
  z-index: 9999; /* Ensure it stays on top */
  min-height: 175px; 
  background-color: rgba(0, 0, 0, 0.80);
  border-radius: 97px 0 0 97px;
  width: 250px; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 2px 5px; 
}

    #swipe-url {
    background: -webkit-linear-gradient(0deg, #056cb8 0, #35ccf5 100%);
    box-shadow: none !important;
    font-weight: 700 !important;
    color: #ffffff !important;
    border-bottom: 3px solid #35ccf5;
    font-size: 20px;
    width: 180px;
    height: 45px;
    margin-left: 35px;
  }
  .swiper-link {
    display: inline-block;
    border-radius: 6px;
    background-color: #84d32e;
    padding: 3px 3px 1.5px 3px; 
    font-size: 10px; 
    line-height: 14px; 
    width: 190px;
    height: 58px;
    text-decoration: none;
    margin-left: 35px;
  }

  .swiper-image-container {
    min-height: 50px
  }

  .swiper-image {
    width: 77%; 
    margin-left: 40px;
  }

  /* swipe now */
  .swiper-link-text {
    text-align: center;
    font-size: 19px;
    margin-top: 6px;
    font-weight: 800;
  
  }

    /* 50% off */
  .swiper-link-highlight {
    display: block;
    font-size: 18px; 
    font-weight: 1000;
    color: #fff623;
    margin-top: 8px;
    
  }
  
  }
  @media (min-width: 1024px) {
    .swiper-container{
    top: 500px;
  }
}



  /* 1023 and under*/
  @media (max-width: 1024px) and (min-device-width: 769px) {
    #swipe-url {
    background: -webkit-linear-gradient(0deg, #056cb8 0, #35ccf5 100%);
    box-shadow: none;
    font-weight: 700;
    color: #ffffff;
    border-bottom: 3px solid #35ccf5;
    font-size: 20px;
    width: 190px;
    height: 50px;
    margin-left: 35px;
  }
  .swiper-link {
    display: inline-block;
    border-radius: 6px;
    background-color: #84d32e;
    padding: 3px 3px 1.5px 3px; 
    font-size: 10px; 
    line-height: 14px; 
    width: 190px;
    height: 58px;
    text-decoration: none;
    margin-left: 35px;
  }

  .swiper-container {
    min-height: 175px; /* Adjusted for mobile */
    background-color: rgba(0, 0, 0, 0.85);
    border-radius: 97px 0 0 97px;
    width: 250px; /* Adjusted for mobile */
    position: fixed;
    top: 175px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 5px; /* Adjusted for mobile */
    padding-bottom: 2px; /* Adjusted for mobile */
    right: 0;
    z-index: 999;
  }

  .swiper-image-container {
    min-height: 50px
  }

  .swiper-image {
    width: 77%; 
    margin-left: 20px;
  }

  /* swipe now */
  .swiper-link-text {
    text-align: center;
    font-size: 19px;
    margin-top: 8px;
    font-weight: 800;
  
  }

    /* 50% off */
  .swiper-link-highlight {
    display: block;
    font-size: 20px; 
    font-weight: 1000;
    color: #fff623;
    margin-top: 10px;
    
  }
  
  }


  /* 767 and under*/
  @media (max-width: 768px) {
    #swipe-url {
    box-shadow: none;
    font-weight: 700;
    color: #ffffff;
    width: 100%;
  }
  .swiper-link {
    display: inline-block;
    border-radius: 6px;
    padding: 5px 5px 2.5px 5px;
    font-size: 12px;
    line-height: 16px;
    max-width: 190px;
    text-decoration: none;
  }

  .swiper-container { 
    min-height: auto;
    background-color: rgba(0, 0, 0, 0.80);
    border-radius: 97px 0 0 97px;
    width: 150px;
    position: fixed;
    top: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 5px 10px 15px;
    justify-content: flex-end;
    right: 0;
    z-index: 999;
    }

    /* container for swiping */
  .swiper-image-container {
    min-height: 40px;
    margin-left: 0px;
    }

  .swiper-image {
    height: 70px;
    width: 100%;
    /* background-color: red; */
    }
  .swiper-link-text {
    font-size: 18px;
    margin: 0;
    text-align: center;
    margin-top: -5px;
    margin-left: 0px;
  }

  .swiper-link-highlight {
    display: block;
    font-size: 26px;
    font-weight: 1000;
    color: #fff623;
    margin-top: 10px;
    margin-left: 15px;
  }
}
  
</style>
