<script>
  import stars from "/src/images/stars.png";
  import verify from "/src/images/verify.png";
  export let review;
</script>


<style>
  .review-card {
    width: 100%;
    min-height: 50px;
  }

  .review-image {
    width: 100%;
  }

  .review-content {
    min-height: 50px;
    padding: 20px 15px 25px;
    border: 1px solid #e8e8e8;
    border-top: none;
  }

  .review-stars {
    margin-bottom: 20px;
    width: 87.5px;
  }

  .review-description {
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .review-author {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
  }

  .author-name {
    color: #00aeef;
  }

  .verification {
    display: flex;
    align-items: center;
  }

  .verify-text {
    margin-left: 10px;
    font-size: 13px;
    line-height: 16px;
    color: #4a4a4a;
    font-weight: bold;
  }
</style>


<div class="review-card">
  <img src={review.image} alt="review 1" class="review-image" />
  <div class="review-content">
    <img src={stars} alt="stars" class="review-stars" />
    <div class="review-description">
      {review.description}
    </div>
    <div class="review-author">
      <span class="author-name">{review.name}</span>
      - {review.location}
    </div>
    <div class="verification">
      <img src={verify} alt="verify" class="verify-icon" />
      <div class="verify-text">Verified Purchase</div>
    </div>
  </div>
</div>
